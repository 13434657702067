import { environment } from '@environments/environment';

const developmentAppRoutes = {
  DEVELOPMENT: 'dev',
  PREVIEW: 'preview',
  FEATURE_PREVIEW: 'feature-preview',
  LANDING_PAGE: 'landing-page',
  PLP: 'plp',
  PDP: 'pdp',
  MY_CART: 'my-cart'
};

export const AppRoutes = {
  ...(environment.production ? {} : developmentAppRoutes),
  ROOT: '/',
  EMPTY: '',
  WILD_CARD: '**',
  PAGE_TYPE: ':pageType',
  MY_ACCOUNT: 'my-account',
  SEARCH: 'search',
  COMPARE_PRODUCT_PAGE: 'compare-products',
  CATALOG_CREATOR_PAGE: 'catalog-creator'
};
